import React, {Component} from 'react'
import { Switch, Route} from 'react-router-dom';
import Survey from './Survey'

class Main extends Component
{
    constructor(){
        super()
        // ReactGA.initialize("UA-135749742-1")
        // ReactGA.pageview(window.location.pathname + window.location.search)
    }

    render()
    { 
        return<div>
                <Route path='/:key' render={(params)=>(<div><Survey {...this.props} {...params}/></div>)} />
            </div>
    }
}

export default Main 
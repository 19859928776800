import * as firebase from 'firebase';
// Initialize Firebase
var config = {
    apiKey: "AIzaSyDU8LquL8LEHACl6zOZPCl6qhvRgKpZ0-M",
    authDomain: "posible-app-prod.firebaseapp.com",
    databaseURL: "https://posible-app-prod.firebaseio.com",
    projectId: "posible-app-prod",
    storageBucket: "posible-app-prod.appspot.com",
    messagingSenderId: "1057547901573"
    
};
firebase.initializeApp(config);

export const database = firebase.firestore()
export const functions = firebase.functions();
export default firebase





import {withRouter} from 'react-router'
import Main from './Main'

const App = withRouter(Main)

export default App


// import React, {Component} from 'react';
// import './App.css';
// import firebase from './Firebase'
// import { css } from '@emotion/core';
// import ClipLoader from 'react-spinners/ClipLoader';
// // import { FacebookProvider, Like } from 'react-facebook';

// const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: red;
// `;

// class App extends Component
// {
//     constructor()
//     {   
//         super()
//         this.state={
//             submissionCompleted:false,
//             saving:false,
//             headerImage: null,
//             footerImage: null,
//             headerText: '',
//             footerText: '',
//             submissionText:'',
//             headerBackgroundColor:'',
//             footerBackgroundColor:'',
//             headerImageAlign:'center',
//             headerImageHeight:'',
//             headerImageWidth:'',
//             footerImageAlign:'center',
//             footerImageHeight:'',
//             footerImageWidth:'',
//             loadingData:true,
//             questions:[],
//             transactionNo:'',
//             transactionID:'',
//             storeID:'',
//             registrationID:'',
//             customerID:'',
//             customerName:'',
//             surveyID:'',
//             surveyRequestID:'',
//             customerEmailID:'',
//             customerMobileNo: '',
//             facebook:'',
//             facebookURL:''
//         }
//         this.getSurveyDetailsBySurveyReuestID= this.getSurveyDetailsBySurveyReuestID.bind(this)
//         this.createStarRating= this.createStarRating.bind(this)
//         this.textChange = this.textChange.bind(this)
//         this.onCheckBoxSelected= this.onCheckBoxSelected.bind(this)
//         this.onSave = this.onSave.bind(this)
//         this.onStarSelected= this.onStarSelected.bind(this)
//         this.onDropDownSelected = this.onDropDownSelected.bind(this)
//         this.saveSurveyResponse=this.saveSurveyResponse.bind(this)
//     }

//     async componentDidMount(){

//       let survey = await this.getSurveyDetailsBySurveyReuestID("RNACaO9Kw7UApThxXGMy")
//       console.log('survey', survey)
//       if(survey)
//         {
//             this.setState({               
//                 headerImage: survey.HeaderImage,
//                 footerImage: survey.FooterImage,
//                 headerText:  survey.HeaderText,
//                 footerText: survey.FooterText,
//                 submissionText: survey.hasOwnProperty('SubmissionText') ? survey.SubmissionText  :'',
//                 headerBackgroundColor:survey.HeaderBackgroundColor,
//                 headerImageAlign:survey.hasOwnProperty('HeaderImageAlign') ? survey.HeaderImageAlign : this.state.headerImageAlign,
//                 headerImageHeight:survey.hasOwnProperty('HeaderImageHeight') ? survey.HeaderImageHeight : this.state.headerImageHeight,
//                 headerImageWidth:survey.hasOwnProperty('HeaderImageWidth') ? survey.HeaderImageWidth : this.state.headerImageWidth,
//                 footerImageAlign:survey.hasOwnProperty('FooterImageAlign') ? survey.FooterImageAlign : this.state.footerImageAlign,
//                 footerImageHeight:survey.hasOwnProperty('FooterImageHeight') ? survey.FooterImageHeight : this.state.footerImageHeight,
//                 footerImageWidth:survey.hasOwnProperty('FooterImageWidth') ? survey.FooterImageWidth : this.state.footerImageWidth,
//                 facebook: survey.hasOwnProperty('Facebook') ? survey.Facebook : '',
//                 facebookURL: survey.hasOwnProperty('FacebookURL') ? survey.FacebookURL :'',
//                 loadingData:false,
//                 questions:survey.Questions,
//                 transactionNo:survey.TransactionNo,
//                 transactionID:survey.TransactionID,
//                 storeID:survey.storeID,
//                 registrationID:survey.RegistrationID,
//                 customerID:survey.CustomerID,
//                 customerName:survey.customerName,
//                 customerEmailID:survey.CustomerEmailID,
//                 customerMobileNo: survey.CustomerMobileNo
//                 })
//             }
//       }


//   async getSurveyDetailsBySurveyReuestID(surveyRequestID){
//       let addMessage = firebase.functions().httpsCallable('getSurveyBySurveyRequestID')
//         return addMessage({ SurveyRequestID: surveyRequestID }).then(function (data) {
//           console.log('', data.data)
//           let survey =data.data
//           return survey
//         })
//   }

//   createStarRating = (index, counter, selected) => {
//     // console.log('selected',selected)
//     let children = [] 
//     for (let j = 1; j <= counter; j++) {
//         children.push(<input key={"input"+ j} type="radio" id={"rate_"+ index +"_" + j} name="rating" value={j} checked={selected>=j? "checked":""}  onChange={()=> this.onStarSelected(index,j)}/>)
//         children.push(<label key={"label"+ j} htmlFor={"rate_"+ index +"_" + j} title="">{j + " stars"}</label>)
//     }
//     return children
//   }

//   textChange(index, text)
//   {
//         let questions = this.state.questions
//         questions[index].Response = text
//         this.setState({questions:questions})
//   }

//   onCheckBoxSelected(questionIndex, optionIndex)
//   {
//     let questions = this.state.questions
//     questions[questionIndex].Options[optionIndex].Response = questions[questionIndex].Options[optionIndex].hasOwnProperty('Response') ? !questions[questionIndex].Options[optionIndex].Response :true  
//     let points = 0
//     questions[questionIndex].Options.map(m =>{
//         if(m.hasOwnProperty('Rating') && m.hasOwnProperty('Response') && m.Response.trim()!=="")
//         {
//             points  = points + Number(m.Rating)
//         }
//     })
//     questions[questionIndex].Points = points
//     this.setState({questions:questions})
//   }

//   onStarSelected(questionIndex,rating)
//   {
//        let questions = this.state.questions
//         questions[questionIndex].Response = rating
//         questions[questionIndex].Points = Number(rating)
//         this.setState({questions:questions})
//   }

//   onDropDownSelected(questionIndex,value, text )
//   {
//         let questions = this.state.questions
//         questions[questionIndex].Response = text
//         questions[questionIndex].Points = Number(value)
//         this.setState({questions:questions})
//   }

//   async onSave()
//   {
//     // console.log('this.state.questions',this.state.questions)
//     this.setState({saving:false, submissionCompleted:false})
//     let surveyReponse = {
//         TransactionNo:this.state.transactionNo,
//         TransactionID:this.state.transactionID,
//         StoreID:this.state.storeID,
//         RegistrationID:this.state.registrationID,
//         CustomerID:this.state.customerID,
//         CustomerName:this.state.customerID,
//         SurveyID:this.state.surveyID,
//         SurveyRequestID:this.state.surveyRequestID,
//         Questions:this.state.questions,
//         Points:0
//     }
//     let points = 0 
//     this.state.questions.map(m=>{
//         points = points + Number(m.hasOwnProperty('Points')? m.Points :0)
//     })
//     surveyReponse.Points = points
//     console.log('surveyReponse', surveyReponse)
//     let status = await this.saveSurveyResponse(surveyReponse)
//     this.setState({saving:false, submissionCompleted:true})
//     if(status)
//     {
//         ///saved
//         //show thank you message
//     }
//     else{

//     }
//   }

//   async saveSurveyResponse(surveyReponse){
//     let addMessage = firebase.functions().httpsCallable('saveSurveyResponse')
//         return addMessage({ SurveyResponse: surveyReponse }).then(function (data) {
//             console.log('saveSurveyResponse data', data)
//             if(data)
//             {
//                 return true
//             }
//             else{
//                 return false
//             }
//         })
//     }

//   render(){
//     return <React.Fragment> 
//     {this.state.loadingData && 
//     <div className='sweet-loading' style={{textAlign:"center"}}>
//         <ClipLoader
//           css={override}
//           sizeUnit={"px"}
//           size={150}
//           color={'#123abc'}
//           loading={this.state.loading}
//         />
//     </div> }
// {!this.state.loadingData && 
// <div className="container">
// <div className="row" style={{backgroundColor:this.state.headerBackgroundColor}}>
//     {this.state.headerImage &&  <div className="col-md-12 col-sm-12" style={{textAlign:this.state.headerImageAlign,marginTop:"20px", marginBottom:"20px"}} >
//         <img src={this.state.headerImage.Preview} width={this.state.headerImageWidth !=="" ? this.state.headerImageWidth + "px": ""} 
//         height={this.state.headerImageHeight !=="" ? this.state.headerImageHeight + "px" : ""}/>
//     </div>}
//     <div className="col-md-12 col-sm-12" style={{marginTop:"20px", marginBottom:"20px"}}>
//         <div dangerouslySetInnerHTML={{__html: this.state.headerText}} />
//     </div>
// </div>
// <br></br>
// <br></br>
// {!this.state.submissionCompleted && <React.Fragment> 
//     <div className="row">
//   {this.state.questions.map((m,index)=> <React.Fragment key={index}>
//     <div className="col-md-12" >
//         <h4>{index+1}. {m.Question}</h4>
//     </div>
//     {m.QuestionTypeID === "1" &&  <div className="col-md-12"><input className="form-control input-lg" 
//         onChange={(evt)=>this.textChange(index, evt.target.value)}
//         value={m.Response}
//     /></div>}
//     {m.QuestionTypeID === "2" &&  <div className="col-md-12"><input className="form-control iput-lg" 
//         onChange={(evt)=>this.textChange(index, evt.target.value)}
//         value={m.Response}
//     /></div>}
//     {m.QuestionTypeID === "3" &&  <React.Fragment> {m.Options.map((o,oIndex)=><div key={oIndex} className="col-md-12" style={{marginLeft:"10px"}}>
//             <label className="checkmark-container">{o.OptionText}
//                 <input type="checkbox" 
//                     value={o.Response}
//                     onChange={()=> this.onCheckBoxSelected(index, oIndex)}></input>
//                 <span className="checkmark"></span>
//          </label>
//     </div>)}
//     </React.Fragment>}
//     {m.QuestionTypeID === "4" &&  <React.Fragment> <div className="col-md-12">
//         <select className="form-control"
//             onChange={(e) => this.onDropDownSelected(index, 
//             e.target.value,
//             e.target.options[e.target.selectedIndex].text)}
//             value={m.Points}
//         >{m.Options.map((o,oIndex)=><option key={oIndex} value={o.hasOwnProperty('Rating') && o.Rating>=0 ? o.Rating : oIndex}>{o.OptionText}</option>)}
//         </select>
//         </div>
//     </React.Fragment>}
//     {m.QuestionTypeID ==="5" && <div className="col-md-12"><fieldset className="starability-basic"> 
//     <input type="radio" id={"rate_"+ index +"_0" } className="input-no-rate" name="rating" value="0" defaultChecked="checked" aria-label="No rating."/>
//     {this.createStarRating(index, m.Rating, Number(m.hasOwnProperty('Response') ? m.Response: 0))}</fieldset></div>}
//     <div className="col-md-12" style={{marginTop:"30px"}}></div>
//     </React.Fragment>)}
// </div>
// <div className="row" style={{padding:"10px"}}>
//     <button type="button" className="btn btn-success btn-md btn-block btn-flat"
//         disabled={this.state.saving}
//                         onClick={() => this.onSave()}>
//                         Submit</button>
// </div>
// </React.Fragment>}
     
// {this.state.submissionCompleted &&  <div className="row" >
// <div className="col-md-12 col-sm-12" style={{marginTop:"20px", marginBottom:"20px"}}>
//         <div dangerouslySetInnerHTML={{__html: this.state.submissionText}} />
//     </div>
// </div>}
// <div className="row" style={{backgroundColor:this.state.footerBackgroundColor}}>
//     {this.state.footerImage &&  <div className="col-md-12 col-sm-12" >
//         <img src={this.state.footerImage.Preview}/>
//     </div>}
//     <div className="col-md-12 col-sm-12">
//         {this.state.footeText}
//     </div>
// </div>
// </div>}
// </React.Fragment>
//   }
// }

// export default App;
